.login-page {
  background-color: #ecf5ff;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page .login-wrapper {
  height: 100%;
  width: 100%;
}

.login-page .login-content {
  width: calc(100%);
  margin: 0 auto;
  padding: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 105px -13px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.power-by-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #AFAFAF;
}

.hello-message {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
}

.greeting-message {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: justify;
  color: #6D6D6D;
}

.botton-text-link span {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
}

.login-form-forgot {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #515151;
}

.text-align-center {
  text-align: center;
}

.margin-top-20 {
  margin-top: 2px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-botton-130 {
  margin-bottom: 130px;
}

.full-height {
  height: calc(100% - 39px);
}

.full-height-100 {
  height: 100%;
}

.aone-eco-system-heading-1 {
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 44px;
  text-transform: uppercase;
  color: #274B78;
}

.app-logo-container {
  width: 144px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.app-logo-container :hover,.select {
  border-radius: 10px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  cursor: pointer;
}

.app-logo-container img {
  width: 100%;
}

.app-logo-container-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.eco-system-see-more-button {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #3C37FF;
}